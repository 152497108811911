import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  REDIRECT,
  COMPANIES_PAGE_LOADED,
} from "../../../constants/actionTypes";
import { toast } from "react-toastify";
import {
  superadmin,
  salesman,
  partner,
  finance,
} from "../../../constants/defaults";
import Content from "../../../components/containers/Content";
import Button from "../../../components/common/Button";
import { Icons } from "../../../constants/icons";
import Loader from "../../../components/Loader";
import services from "../../../api/index";
import SelectList from "../../../components/common/SelectList";

const mapStateToProps = (state) => {
  return {
    companies: state.companies,
    redirectTo: state.common.redirectTo,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onRedirect: () => {
    dispatch({ type: REDIRECT, redirectTo: "/management/users" });
  },
  companiesOnLoad: (payload) => {
    dispatch({ type: COMPANIES_PAGE_LOADED, payload });
  },
});

const EditUser = (props) => {
  const [permissionGroups, setPermissionGroups] = useState([]);
  const [isActiveValue, setIsActiveValue] = useState();
  const [userValues, setUserValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    userRole: "",
    companyInfoId: "",
    selectedPermissionGroups: [],
  });

  const [password, setPassword] = useState("");

  useEffect(() => {
    getUserById();
    getCompanies();
    searchPermissionGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeHandler = (e) => {
    setUserValues({ ...userValues, [e.target.name]: e.target.value });
  };

  const getUserById = () => {
    Promise.all([services.user.getUser(props.match.params.userid)])
      .then((res) => {
        setUserValues({
          firstName: res[0].FirstName,
          lastName: res[0].LastName,
          email: res[0].Email,
          userRole: res[0].Roles[0],
          companyInfoId: res[0].CompanyInfo.Id,
          selectedPermissionGroups: res[0].PermissionGroups,
        });
        setIsActiveValue(res[0].IsActive);
        return res;
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  };

  const getCompanies = (params) => {
    const { keywords = "", pageindex = 0, pagesize = 50 } = params || {};
    props.companiesOnLoad(
      Promise.all([
        services.company.getCompanies(keywords, pageindex, pagesize),
      ])
        .then((res) => {
          return res;
        })
        .catch((err) => {
          if (err.response?.data?.message)
            toast.error(<div>{err.response?.data.message}</div>);
          return undefined;
        })
    );
  };

  const searchPermissionGroup = (params) => {
    const { keywords = "", pageindex = 0, pagesize = 50 } = params || {};
    services.user
      .searchPermissionGroup(pageindex, pagesize, keywords)
      .then((res) => {
        setPermissionGroups(res.Items);
      });
  };

  const updateUser = (params) => {
    const {
      userId = props.match.params.userid,
      firstName = userValues.firstName,
      lastName = userValues.lastName,
      email = userValues.email,
      userRole = userValues.userRole,
      isActive = isActiveValue,
      companyInfoId = userValues.companyInfoId,
      permissionGroup = userValues.selectedPermissionGroups.map((m) => m.Id),
    } = params || {};
    Promise.all([
      services.user.updateUser(
        userId,
        firstName,
        lastName,
        email,
        userRole,
        isActive,
        companyInfoId,
        permissionGroup
      ),
    ])
      .then((res) => {
        toast.success(<div>User updated successfuly!</div>);
        return res;
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
        return undefined;
      });
  };

  const updatePassword = () => {
    const userId = props.match.params.userid;

    Promise.all([services.user.changePassword(userId, password)])
      .then((res) => {
        toast.success(<div>Password updated successfuly!</div>);
      })
      .catch((err) => {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
      });
  };

  const { companyList } = props.companies;

  return (
    <Content pageTitle="User Edit">
      {companyList === undefined ? (
        <Loader />
      ) : (
        <div className="container px-xl-9 mt-lg-3 mb-3">
          <div className="form-check mb-3 d-flex flex-row justify-content-end">
            <input
              className="form-check-input"
              type="checkbox"
              id="isActiveValue"
              name="isActiveValue"
              checked={isActiveValue}
              onChange={(ev) => setIsActiveValue(ev.target.checked)}
            />
            <label className="form-check-label ps-2" htmlFor="isActive">
              Is Active
            </label>
          </div>
          <div className="row">
            <div className="col">
              <div className="mb-3 user-input">
                <label htmlFor="firstName" className="form-label">
                  First Name
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder=""
                  value={userValues.firstName}
                  onChange={changeHandler}
                />
              </div>
            </div>
            <div className="col">
              <div className="mb-3 user-input">
                <label htmlFor="lastName" className="form-label">
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder=""
                  value={userValues.lastName}
                  onChange={changeHandler}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="mb-3 user-input">
                <label htmlFor="userRole" className="form-label">
                  Role
                </label>
                <select
                  className="form-control"
                  aria-label="User Role"
                  id="userRole"
                  name="userRole"
                  value={userValues.userRole}
                  onChange={changeHandler}
                >
                  <option value={partner}>Partner</option>
                  <option value={salesman}>Salesman</option>
                  <option value={superadmin}>SuperAdmin</option>
                  <option value={finance}>Finance</option>
                </select>
              </div>
            </div>
            <div className="col">
              <SelectList
                Title="Permission Group"
                disable={false}
                list={permissionGroups}
                onFilter={(ev) => searchPermissionGroup(ev)}
                listOnChecked={(ev, name) => {
                  let selected = userValues.selectedPermissionGroups;
                  if (ev.target.checked) {
                    selected.push({ Id: ev.target.value, Name: name });
                  } else {
                    selected.splice(selected.indexOf(ev.target.value), 1);
                  }
                  changeHandler({
                    target: {
                      name: "selectedPermissionGroups",
                      value: selected,
                    },
                  });
                }}
                onRemoveName={(item) => {
                  let selected = userValues.selectedPermissionGroups;
                  selected.splice(selected.indexOf(item), 1);
                  changeHandler({
                    target: {
                      name: "selectedPermissionGroups",
                      value: selected,
                    },
                  });
                }}
                selectedList={userValues.selectedPermissionGroups}
              ></SelectList>
            </div>
          </div>
          <div className="mb-3 user-input">
            <label htmlFor="email" className="form-label">
              Email address
            </label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="name@example.com"
              value={userValues.email}
              onChange={changeHandler}
            />
          </div>
          <div className="mb-3 user-input">
            <label htmlFor="company" className="form-label">
              Company
            </label>
            <select
              aria-label="Company"
              id="company"
              name="companyInfoId"
              value={userValues.companyInfoId}
              onChange={changeHandler}
              placeholder="select your company"
            >
              {companyList[0]?.Items?.map((item, index) => {
                return (
                  <option key={index} value={item.Id}>
                    {item.Name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="text-end">
            <Button
              className="confirm-button px-4 me-0"
              bindEvent={() => updateUser()}
              buttonIcon={Icons.thickIcon}
              label="Update User"
            />
          </div>

          <div className="mb-3 user-input">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <input
              type="password"
              id="newPass"
              name="newPass"
              placeholder=""
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="text-end">
            <Button
              className="confirm-button px-4 me-0"
              bindEvent={() => updatePassword()}
              buttonIcon={Icons.thickIcon}
              label="Update Password"
            />
          </div>
        </div>
      )}
    </Content>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
