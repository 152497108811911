import React, { useState, useEffect } from "react";
import PromotionCard from "../../../../../components/common/PromotionCard";
import { connect } from "react-redux";
import PromotionHeader from "../../../../../components/common/PromotionHeader";
import {
  MANAGEMENT_SELECTED_REGION_FIELD,
  MANAGEMENT_PROMOTION_UPDATE_FIELD,
  MANAGEMENT_SELECTED_MERKUR_REGION_FIELD,
} from "../../../../../constants/actionTypes";
import Loader from "../../../../../components/Loader";
import { fixTimezoneOffset } from "../../../../../functions/dateConverter";
import services from "../../../../../api";
import { toast } from "react-toastify";

let date = new Date();

const mapStateToProps = (state) => {
  return {
    promotions: state.promotions,
    prices: state.promotions.selectedProduct?.Prices,
  };
};
const mapDispatchToProps = (dispatch) => ({
  onChangePrice: (price) => {
    dispatch({
      type: MANAGEMENT_SELECTED_REGION_FIELD,
      value: price,
    });
    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "changePercentage",
      value: [],
    });
    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "calculatedAverage",
      value: null,
    });

    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "setOS",
      value: [],
    });
    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "setPlatforms",
      value: [],
    });

    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "tier1Price",
      value: 0,
    });

    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "tier2Price",
      value: 0,
    });
    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "tier3Price",
      value: 0,
    });
    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "tier4Price",
      value: 0,
    });
    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "minQuantity",
      value: 1,
    });
    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "maxQuantity",
      value: 1000,
    });
    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "validTo",
      value: fixTimezoneOffset(
        new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          23,
          30,
          0,
          0
        ).setMinutes(30)
      ),
    });
    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "validFrom",
      value: fixTimezoneOffset(new Date().setMinutes(30)),
    });
    dispatch({
      type: MANAGEMENT_PROMOTION_UPDATE_FIELD,
      key: "checkPromotion",
      value: false,
    });
  },
  onChangeMerkurRegion: (value) => {
    dispatch({
      type: MANAGEMENT_SELECTED_MERKUR_REGION_FIELD,
      value,
    });
  },
});

function SelectRegions(props) {
  const [isLoading, setLoading] = useState(true);
  const [regions, setRegions] = useState();
  const [merkurRegions, setMerkurRegions] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const data = await services.regions.getRegions();
        setMerkurRegions(data);
      } catch (err) {
        if (err.response?.data?.message)
          toast.error(<div>{err.response?.data.message}</div>);
      }
    })();
  }, []);

  useEffect(() => {
    setRegions(props.prices?.sort((a, b) => a.Cost - b.Cost));
    setLoading(false);
  }, [props.prices]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="container-fluid">
            <div className="row">
              <PromotionHeader
                product={props.selectedProduct?.Name}
                title="Select Provider Countries"
                goToSelectGame={() => props.GoToSelectGame()}
              />
              <div className="d-flex flex-row justify-content-center">
                <div className="select-region-cards-container row">
                  {regions?.map((item, key) => {
                    return (
                      <div
                        className="d-flex justify-content-center col p-0 mt-1"
                        key={key}
                      >
                        <PromotionCard
                          item={item}
                          region={{ regions: item.Countries }}
                          id={key + 1}
                          key={key + 1}
                          selected={
                            props.promotions.selectedRegion.filter(
                              (f) => f.Id === item.Id
                            ).length > 0
                              ? true
                              : false
                          }
                          changePrice={(Id) => {
                            props.onChangePrice(item);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <hr className="mt-5 mb-5" />
            <div className="row">
              <div className="promotion-header" style={{ marginTop: 0 }}>
                <label>Select Merkur Region</label>
              </div>
              <div className="d-flex flex-row justify-content-center user-input mb-3">
                <select
                  style={{ width: "50%", minWidth: 250 }}
                  onChange={(e) => {
                    let value = e?.target?.value || null;
                    value = value === "Default" ? null : value;
                    props.onChangeMerkurRegion(value);
                  }}
                  value={props.promotions.selectedMerkurRegion}
                >
                  <option value={null}>Default</option>
                  {merkurRegions?.map((e) => (
                    <option key={e.Region} value={e.Region}>
                      {e.Region}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(SelectRegions);
